export const SubscribeStar: React.FC = () => {
    return (
        <svg id="subscribe-star-logo" width="166" height="166" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="166" height="166">
                <circle cx="82.5227" cy="82.5227" r="82.5227" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path d="M124.822 92.9017V114.7L96.4062 114.311V107.175C91.5189 107.088 81.6404 106.967 81.2252 107.175C80.81 107.382 74.7808 108.645 72.402 110.029C72.402 110.029 74.8673 114.57 76.8136 117.036C78.7599 119.501 83.0417 124.042 87.0641 127.286C91.0864 130.53 99.131 137.407 105.229 142.727C111.328 148.047 118 157.497 118 157.497C118 157.497 111.453 160.661 102.5 162.997C93.547 165.332 84.5 164.997 84.5 164.997C84.5 164.997 76.5541 157.648 73.44 155.053C70.3259 152.458 66.3036 150.382 59.0375 142.727C51.7713 135.071 48.5275 130.53 48.5275 130.53C48.5275 130.53 40.4828 119.371 39.8341 104.709C39.1853 90.0472 42.8184 80.705 42.8184 80.705L69.2879 55.1437C69.2879 55.1437 75.7755 53.7164 81.0954 53.7164C86.4153 53.7164 90.8269 54.2354 90.8269 54.2354C90.8269 54.2354 106.527 56.052 115.35 67.9892C124.173 79.9265 124.822 92.9017 124.822 92.9017Z" className="ss-lower-s" />

                <path d="M92 54.5C92 54.5 80.1871 37.9721 70.5854 32.437C66.8431 30.2797 61.9043 25.1312 58.1291 20.7593C52.2181 13.914 48.0084 7.52451 48.0084 7.52451C48.0084 7.52451 56.4423 3.50218 64.3572 1.68564C72.2721 -0.130899 80.8358 -0.00114644 80.8358 -0.00114644C80.8358 -0.00114644 83.3011 2.72366 85.8962 5.05921C88.4912 7.39476 91.9946 10.2493 103.024 19.5915C114.053 28.9337 122.616 41.6495 125.471 54.1057C128.325 66.562 123 83.5 123 83.5C123 83.5 119.747 72.7662 113 65.5C106.253 58.2338 92 54.5 92 54.5Z" className="ss-upper-s" fill="var(--upper-s)" />
                <path d="M69.4177 68.3784C69.0284 65.7834 69.4177 50.6023 69.4177 50.6023H41.3911L42.0399 74.866C42.0399 74.866 42.1696 86.933 49.6953 97.8323C57.2209 108.731 63.9681 108.991 63.9681 108.991C63.9681 108.991 72.6615 112.235 80.9657 112.235C89.2698 112.235 96.536 110.808 96.536 110.808V99.5191C96.536 99.5191 96.2765 93.1612 94.46 88.7496C92.6434 84.338 86.6748 83.0405 84.988 83.0405C83.3012 83.0405 81.7442 83.4297 79.4086 83.0405C77.0731 82.6512 75.6767 82.0024 73.8293 80.3157C71.9818 78.6289 69.8069 70.9735 69.4177 68.3784Z" className="ss-upper-s" />
            </g>
            <circle cx="82.5227" cy="82.5195" r="78.5227" className="ss-outline" strokeWidth="8" />
        </svg>
    );
}